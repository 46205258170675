/* ******************** Navbar ************************** */

html {
  max-width: 100vw !important;
  margin: 0px;
  padding: 0px;
}

/* .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: white;
  color: black;
  height: 80px; 
}

.logo {
  display: flex;
  align-items: center; 
}

.nav-text {
  padding-top: 60px;
  margin-left: -178px;
}

.nav-text p {
  color: darkgoldenrod;
  line-height: 1px;
  font-size: 15px;
}

.logo img {
  height: 120px; 
  width: auto;
}

.logo h1 {
  padding-top: 10px;
  color: darkgoldenrod;
}


.nav-links {
    list-style: none;
    display: flex;
    gap: 3rem;
}

.nav-links li {
    display: inline;
}

.nav-links a {
    color: rgb(77, 76, 76);
    text-decoration: none;
    font-family: Arial;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 18px;
    letter-spacing: 1px;
    position: relative;
    overflow: hidden;
}

.nav-links a::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: black;
    transform: scaleX(0);
    transition: transform 0.3s ease;
}

.nav-links a:hover::after {
    transform: scaleX(1);
} */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  position: relative;
  /* For absolute positioning of the menu */
}

.logo {
  padding-left: 30px;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 40px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.nav-links li a {
  color: black;
  text-decoration: none;
  padding: 0px;
  font-size: 19px;
  position: relative;
}

.logo-nav {
  width: 170px;
}

.nav-links li a::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  height: 2px;
  width: 100%;
  background: #007BFF;
  transform: translateX(-50%) scaleX(0);
  transition: transform 0.3s ease;
}

.nav-links li a:hover::after {
  transform: translateX(-50%) scaleX(1);
}

/* Responsive Styles */
.hamburger {
  display: none;
  /* Hidden by default */
  font-size: 30px;
  padding: 15px;
  cursor: pointer;
}

/* ----------------------------12th---------------------------------------- */
.main-div-12th {
  width: 100%;
  min-height: 171px;
  padding-left: 7rem;
  padding-right: 20px;
  padding-top: 13px;
  display: flex;
  align-items: start;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  /* padding-bottom: 13px; */
  background-color: #afe7af;
  position: sticky;
  top: 0;
  box-shadow: 0px 10px 5px #f1f1f1;


  h1 {
    margin: 0px !important;
    font-size: 1.7rem;
    font-weight: 700;
    margin-top: 0px;
    margin-bottom: 1rem;
    color: black;
    font-family: Arial, sans-serif;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }

  button {
    background-color: transparent;
    border: 0.8px solid black;
    border-radius: .3rem;
    color: black;
    transition: all 100ms ease-in;
    font-size: 17px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }

  button:hover {
    background-color: transparent;
    transition: all 100ms ease-in;
    color: #ccc;
  }

  p {
    padding: 0px;
    margin: 0px !important;
    font-size: .75rem !important;
  }

  span {
    text-align: center;
  }
}



/* -----------------------------onlinetes --------------------------------------*/

.online-test {
  padding: 3rem 11rem;
  background-color: #f1f1f1 !important;
  padding-top: 0px !important;

  h1 {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: 1px solid black;
    display: flex;
    align-items: end;
    justify-content: space-between;
    margin-top: 0px !important;

    p {
      font-weight: 700;
      font-size: 1.7rem;
      margin: 0px;
      padding: 0px;
      color: black;
      padding-bottom: 10px;
      font-family: Arial, Helvetica, sans-serif;
    }

    div {
      width: 90px;
      height: auto;

      img {
        height: 100%;
        width: 100%;
      }
    }
  }
}


.online-box {
  display: flex;
  width: 100%;
  /* border: 1px solid black; */
  padding: 20px 10px;
  list-style: none;
  gap: 20px
}

.li-for-online-box {
  width: 33%;
  height: auto;
  cursor: pointer;
  /* border: 1px solid black; */
  border-radius: .7rem;
  padding: 20px 13px;
  background-color: #e5e5e5;
  transition: all 100ms;
  box-shadow: 10px 5px 0px rgb(202, 202, 202);
}

.li-for-online-box:hover {
  transition: all 100ms;
  background-color: #c9c9c9;
  box-shadow: 0px 0px 0px rgb(202, 202, 202);
}

.div1 {
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;

  div {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 10px;
    padding: 25px 0px;

    h3 {
      color: black;
      margin: 0px;
      padding: 0px;
      font-family: Arial, Helvetica, sans-serif;
    }

    p {
      font-family: Arial, Helvetica, sans-serif;
      color: black;
      margin: 0px;
      padding: 0px;
    }
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: blue;
    padding: 4px;
    border-radius: 10px;

    p {
      font-family: Arial, Helvetica, sans-serif;
      font-size: .6rem;
      margin: 0px;
      font-weight: 600;
      letter-spacing: .0175rem;
      padding: 3px !important;
      color: white !important;
    }
  }
}



.div2 {
  list-style: none;
  display: flex;
  align-items: start;
  justify-content: center;
  padding: 0px;
  flex-direction: column;

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;
    padding: 10px 0px;

    p {
      color: black;
      font-size: 15px;
      margin: 0px;
      padding: 0px;
    }
  }
}


.div3 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

}

.first-div-div3 {
  width: 100%;
  text-align: start;
  font-size: 10px;
  color: black;
  text-decoration: line-through;
}


.second-div-div3 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    padding: 5px;
    margin: 0px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      margin: 0px;
      padding: 0px;
      font-size: 15px;
      color: #007bff;
      font-weight: 600;
    }
  }

  span {
    color: black;
    font-size: 19px;
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
  }

  button:hover {
    background-color: transparent;
  }
}

/* --------------------------submenu--------------------------------------- */


.submenu {
  padding: 0px;
  border-radius: 10px;
  color: white;
  background-color: white;
  z-index: 20;
  min-width: 150px;
  top: 50px;

  right: -100%;
  overflow: hidden;


  li {
    width: auto;
    padding: 8px 10px;
    color: black;
    list-style: none;
    cursor: pointer;
    overflow: hidden;
    margin: 5px;
    padding-left: 10px;
    border-radius: 5px;
    transition: all 100ms ease-in;
  }

  li:hover {
    cursor: pointer;
    background-color: #ccc;
    transition: all 100ms ease-in;
  }
}

/* Media Queries */
@media (max-width: 768px) {
  .nav-links {
    /* display: none;  */
    flex-direction: column;
    /* Stack links vertically */
    position: absolute;
    /* Positioning for dropdown */
    top: 60px;
    /* Adjust based on navbar height */
    left: 0;
    right: 0;
    background-color: white;
    /* Match background */
  }

  .nav-links.active {
    display: flex;
    /* Show when active */
  }

  .hamburger {
    display: block;
    /* Show hamburger icon */
  }

  .mnav_links {
    display: flex !important;
  }

  .nav-links-div {
    display: none;
  }

}

/* ******************** m-nav ************************** */

.mnav_links {
  display: none;
  flex-direction: column;
  list-style-type: none;
  row-gap: 0px;
  text-decoration: none;
  align-items: start;
}

.li {
  text-decoration: none;
  color: black;
  font-weight: bold;
  font-size: 18px;
  height: 30px;
}

/* ******************** pannel1 ************************** */

.home-pannel1 {
  position: relative;
  height: auto;
  /* Change to auto to allow content to define height */
  min-height: 400px;
  /* Maintain a minimum height */
  max-width: 100% !important;
  text-align: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("https://img.freepik.com/free-vector/background-template-wave-gradient-style_483537-5027.jpg");
  padding: 20px;
  /* Add padding for spacing */
}

.home-pannel1 h2 {
  text-align: center;
  color: white;
  font-size: 2rem;
  /* Use rem for responsive font size */
  padding-top: 30px;
}

.home-pannel1 h1 {
  color: white;
  font-size: 3rem;
  margin-top: 5px !important;
  margin-bottom: 10px;
  /* Use rem for responsive font size */
}

.home-pannel1 p {
  color: white;
  font-weight: 400;
  text-transform: none;
  font-size: 18px;
  /* Use rem for responsive font size */
  line-height: 1.5;
  width: auto;
  max-width: 800px;
  /* Add max-width for better readability */
  margin: 0 auto;
  /* Center the paragraph */
}

.home-pannel1 button {
  font-family: Arial;
  font-weight: bold;
  font-size: 18px;
  /* Use rem for responsive font size */
  letter-spacing: 1px;
  padding: 15px 20px;
  border-radius: 20px;
  background-color: rgba(0, 102, 255, 0.93);
  color: #ffffff;
  text-decoration: none;
  margin-top: 30px;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .home-pannel1 h1 {
    font-size: 3rem;
    /* Adjust font size for smaller screens */
  }

  .home-pannel1 h2 {
    font-size: 1.5rem;
    /* Adjust font size for smaller screens */
  }

  .home-pannel1 p {
    font-size: 1.2rem;
    /* Adjust font size for smaller screens */
  }

  .home-pannel1 button {
    font-size: 1.2rem;
    /* Adjust font size for smaller screens */
  }
}

@media (max-width: 480px) {
  .home-pannel1 h1 {
    font-size: 2.5rem;
    /* Further adjust for mobile devices */
  }

  .home-pannel1 h2 {
    font-size: 1.2rem;
    /* Further adjust for mobile devices */
  }

  .home-pannel1 p {
    font-size: 1rem;
    /* Further adjust for mobile devices */
  }

  .home-pannel1 button {
    font-size: 1rem;
    /* Further adjust for mobile devices */
    padding: 10px 15px;
    /* Adjust padding for smaller screens */
  }
}


/* ******************** pannel2 ************************** */

/* Base styles for larger screens (desktop) */
.home-pannel2 {
  height: 740px;
  width: 100%;
  text-align: center;
}

.home-pannel2 p {
  text-transform: uppercase;
  font-size: 26px;
  padding-top: 20px;
}

.home-pannel2 h1 {
  letter-spacing: 0.02em;
  font-weight: 900;
  text-transform: uppercase;
  text-align: center;
  margin-top: -18px;
  margin-bottom: 40px;
  font-size: 30px;
}

.home-pannel2-img {
  display: flex;
  justify-content: space-evenly;
  height: auto;
  width: 100%;
}

.home-pannel2 img {
  height: 500px;
  width: 470px;
}

@media (max-width: 1200px) {
  .home-pannel2 h1 {
    font-size: 45px;
  }

  .home-pannel2 img {
    height: 700px;
    width: auto;
  }
}

@media (max-width: 992px) {
  .home-pannel2 {
    height: auto;
  }

  .home-pannel2 h1 {
    font-size: 36px;
    margin-top: 0;
  }

  .home-pannel2 p {
    font-size: 22px;
  }

  .home-pannel2-img {
    flex-direction: column;
    align-items: center;
  }

  .home-pannel2 img {
    height: auto;
    width: 90%;
    max-width: 500px;
  }
}

@media (max-width: 768px) {
  .home-pannel2 h1 {
    font-size: 28px;
  }

  .home-pannel2 p {
    font-size: 18px;
  }

  .home-pannel2-img img {
    width: 100%;
    /* Make images full width on very small screens */
    height: auto;
    /* Maintain aspect ratio */
  }
}

/* ******************** pannel3 ************************** */

.home-pannel3-container {
  height: auto;
  max-width: 100%;
  text-align: center;
  padding: 20px;
}

.top-content {
  margin-bottom: 40px;
}

.home-pannel3-top-heading {
  font-size: 2em;
  margin-bottom: 10px;
  color: #666;
  line-height: 1.2;
  text-transform: uppercase;
}

.top-paragraph {
  font-size: 5em;
  color: black;
  font-weight: bold;
  line-height: 1.2;
  /* Adjusted line height */
}

.home-pannel3-boxes-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.home-pannel3-box {
  padding: 20px;
  height: auto;
  /* Changed to auto for better responsiveness */
  width: 100%;
  /* Set to 100% to make it responsive */
  max-width: 350px;
  /* Added max-width for box size */
  text-align: center;
  box-sizing: border-box;
  /* Ensures padding is included in width */
}

.box-logo {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}

.box-heading {
  font-weight: 700;
  font-size: 2.5em;
  /* Adjusted for better responsiveness */
  margin: 10px 0;
}

.box-paragraph {
  font-size: 1.5em;
  /* Adjusted for better responsiveness */
  color: #333;
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
  .home-pannel3-top-heading {
    font-size: 1.8em;
  }

  .top-paragraph {
    font-size: 4em;
  }

  .box-heading {
    font-size: 2.2em;
  }

  .box-paragraph {
    font-size: 1.4em;
  }
}

@media (max-width: 992px) {
  .home-pannel3-top-heading {
    font-size: 1.6em;
  }

  .top-paragraph {
    font-size: 3.5em;
  }

  .box-heading {
    font-size: 2em;
  }

  .box-paragraph {
    font-size: 1.2em;
  }
}

@media (max-width: 768px) {
  .home-pannel3-top-heading {
    font-size: 1.4em;
  }

  .top-paragraph {
    font-size: 3em;
  }

  .home-pannel3-box {
    width: 90%;
    /* Make boxes take more width on smaller screens */
  }

  .box-heading {
    font-size: 1.8em;
  }

  .box-paragraph {
    font-size: 1.1em;
  }
}

@media (max-width: 576px) {
  .home-pannel3-top-heading {
    font-size: 1.2em;
  }

  .top-paragraph {
    font-size: 2.5em;
  }

  .box-heading {
    font-size: 1.6em;
  }

  .box-paragraph {
    font-size: 1em;
  }
}


/* ******************** pannel4 ************************** */

.home-pannel4-container {
  height: auto;
  width: 100%;
  text-align: center;
  /* padding: 20px; */
}

.top-content {
  margin-bottom: 40px;
}

.home-pannel4-top-heading {
  font-size: 2em;
  margin-bottom: 10px;
  color: #666;
  line-height: 1;
  /* Adjusted for better spacing */
  text-transform: uppercase;
}

.top-paragraph {
  font-size: 2rem;
  margin-bottom: 10px !important;
  margin-top: 10px !important;
  color: black;
  font-weight: bold;
  line-height: 1;
  /* Adjusted for better spacing */
}

.home-pannel4-boxes-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  color: #800080;
}

.home-pannel4-box {
  padding: 20px;
  height: auto;
  /* Changed to auto for flexibility */
  width: 100%;
  /* Change to 100% for better responsiveness */
  max-width: 350px;
  /* Max width to maintain size */
  text-align: center;
  box-sizing: border-box;
  /* Ensures padding is included in width */
}

.box-logo {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}

.home-pannel4-box-heading {
  font-weight: 700;
  font-size: 60px;
  margin: 10px 0;
}

.box-paragraph {
  font-size: 19px;
  color: #333;
}

/* Responsive styles */
@media (max-width: 768px) {
  .home-pannel4-top-heading {
    font-size: 1.5em;
    /* Adjust for smaller screens */
  }

  .top-paragraph {
    font-size: 2.5em;
    /* Adjust for smaller screens */
  }

  .home-pannel4-box-heading {
    font-size: 40px;
    /* Adjust for smaller screens */
  }

  .box-paragraph {
    font-size: 20px;
    /* Adjust for smaller screens */
  }
}

@media (max-width: 480px) {
  .home-pannel4-top-heading {
    font-size: 1.2em;
    /* Further adjust for very small screens */
  }

  .top-paragraph {
    font-size: 2em;
    /* Further adjust for very small screens */
  }

  .home-pannel4-box-heading {
    font-size: 30px;
    /* Further adjust for very small screens */
  }

  .box-paragraph {
    font-size: 18px;
    /* Further adjust for very small screens */
  }

  .home-pannel4-box {
    width: calc(100% - 40px);
    /* Ensures proper padding and responsiveness */
  }
}


/* ******************** pannel5 ************************** */

.home-pannel5-container {
  height: auto;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
}

.top-content {
  margin-bottom: 20px;
}

.home-pannel5-top-content h1 {
  height: 35px;
  font-size: 3em;
  line-height: 0%;
}

.home-pannel5-top-content p {
  padding-bottom: 15px;
  font-size: 19px;
  color: #666;
}

.image-boxes {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  /* Allow wrapping for smaller screens */
}

.image-box {
  width: 40%;
  box-sizing: border-box;
}

.image-box img {
  width: 100%;
  height: auto;
}

.bottom-content {
  margin-top: 10px;
}

.bottom-content h2 {
  margin: 0;
  font-size: 2.2em;
}

.bottom-content p {
  margin: 5px 0 0;
  font-size: 19px;
  color: #666;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .home-pannel5-top-content h1 {
    font-size: 3em;
    /* Adjusting font size for smaller screens */
  }

  .home-pannel5-top-content p {
    font-size: 20px;
    /* Smaller paragraph text */
  }

  .image-box {
    width: 90%;
    /* Full width on smaller screens */
    margin: 10px 0;
    /* Add margin for spacing */
  }

  .bottom-content h2 {
    font-size: 1.8em;
    /* Smaller heading size */
  }

  .bottom-content p {
    font-size: 1.1em;
    /* Smaller paragraph text */
  }
}

@media (max-width: 480px) {
  .home-pannel5-top-content h1 {
    font-size: 2.5em;
    height: 35px;
    /* Further reduce heading size */
  }

  .home-pannel5-top-content p {
    font-size: 18px;
    /* Adjust paragraph size for mobile */
  }

  .image-box {
    width: 100%;
    /* Full width on mobile */
  }

  .bottom-content h2 {
    font-size: 1.5em;
    /* Further reduce heading size */
  }

  .bottom-content p {
    font-size: 1em;
    /* Further reduce paragraph size */
  }
}

/* ******************** pannel6 ************************** */

.home-pannel6-container {
  padding: 20px;
  text-align: center;
}

.home-pannel6-header-section {
  margin-bottom: 20px;
}

.home-pannel6-header-section h1 {
  font-size: 2.2rem;
  margin: 0;
}

.home-pannel6-header-section p {
  font-size: 19px;
  color: #666;
}

.home-pannel6-boxes-container {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  flex-wrap: wrap;
  /* Allow boxes to wrap on smaller screens */
}

.home-pannel6-box {
  align-items: center;
  display: flex;
  padding: 20px;
  width: 30%;
  text-align: center;
  flex-direction: column;
}

.home-pannel6-box h2 {
  font-size: 1.5em;
  margin: 0 0 10px;
}

.home-pannel6-box img {
  max-width: 100%;
  /* Allow images to scale with the box */
  height: auto;
  margin-bottom: 0px;
}

.home-pannel6-box p {
  font-size: 19px;
  color: #333;
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
  .home-pannel6-box {
    width: 45%;
    /* Change to two boxes per row */
  }
}

@media (max-width: 768px) {
  .home-pannel6-header-section h1 {
    font-size: 2em;
    /* Smaller header on smaller screens */
  }

  .home-pannel6-header-section p {
    font-size: 20px;
    /* Smaller paragraph on smaller screens */
  }

  .home-pannel6-box {
    width: 100%;
    /* Full width boxes on very small screens */
  }
}


/* ******************** pannel7 ************************** */

.home-pannel7-container {
  height: auto;
  width: 100%;
}

.home-pannel7-container h1 {
  text-align: center;
  font-size: 25px;
  padding-top: 10px;
  text-transform: uppercase;
}

.home-pannel7-container img {
  height: 500px;
  width: 100% !important;
  padding-left: 0px;
  /* Consider using a more responsive method */
}

/* Boxes container styling */
.boxes-container {
  display: grid;
  justify-content: space-around;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin: 20px 0;
}

.box {
  padding: 20px;
}

.box h2 {
  font-size: 25px;
  margin-bottom: 10px;
  font-weight: 500;
}

.box ul {
  list-style: none;
  padding: 0;
}

.box ul li {
  margin-bottom: 5px;
  font-size: 18px;
  color: rgb(80, 78, 78);
}

/* Responsive styles */
@media (max-width: 1200px) {
  .home-pannel7-container h1 {
    font-size: 2.5em;
  }

  .boxes-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .home-pannel7-container img {
    padding-left: 20px;
    /* Adjusted for smaller screens */
  }
}

@media (max-width: 768px) {
  .home-pannel7-container h1 {
    font-size: 2em;
  }

  .boxes-container {
    grid-template-columns: 1fr;
    /* Stacks boxes on top of each other */
  }

  .home-pannel7-container img {
    padding-left: 0;
    /* Center the image */
    display: block;
    /* Ensure it's block-level */
    margin: 0 auto;
    /* Centering */
  }

  .box h2 {
    font-size: 1.8em;
    /* Adjusted font size */
  }

  .box ul li {
    font-size: 20px;
    /* Adjusted font size */
  }
}

@media (max-width: 480px) {
  .home-pannel7-container h1 {
    font-size: 1.5em;
    /* Further reduced font size for small screens */
  }

  .box h2 {
    font-size: 1.5em;
    /* Further reduced font size */
  }

  .box ul li {
    font-size: 18px;
    /* Further reduced font size */
  }
}


/* ******************** pannel8 ************************** */

.home-pannel8-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #c9c9d3;
}

.home-pannel8-header-content {
  text-align: center;
  margin-bottom: 20px;
}

.home-pannel8-header-content h1 {
  font-size: 30px;
  margin-bottom: 0px;
  margin-top: 0px;
  /* Adjust font size with media queries */
  text-transform: uppercase;
}

.home-pannel8-header-content p {
  font-size: 20px;
  margin-top: 10px;
  /* 27px in rem for better scaling */
  color: #3d3c3c;
}

.home-pannel8-content-sections {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  flex-wrap: wrap;
  /* Allow sections to wrap on smaller screens */
}

.home-pannel8-left-section,
.home-pannel8-right-section {
  width: 48%;
}

.home-pannel8-left-section h2 {
  margin-bottom: 10px;
  font-size: 30px;
  /* Use rem for better scalability */
}

.home-pannel8-left-section ul {
  list-style: none;
  padding: 0;
}

.home-pannel8-left-section li {
  margin-bottom: 10px;
  font-size: 19px;
  /* 25px in rem */
  color: #3d3c3c;
}

.home-pannel8-right-section iframe {
  width: 100%;
  /* Use 100% for responsiveness */
  max-width: 700px;
  /* Limit max width */
  /* height: auto;  */
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {

  .home-pannel8-left-section,
  .home-pannel8-right-section {
    width: 100%;
    /* Stack sections on smaller screens */
    margin-bottom: 20px;
    /* Add spacing between stacked sections */
  }

  .home-pannel8-header-content h1 {
    font-size: 2.5em;
    /* Smaller font size on mobile */
  }

  .home-pannel8-header-content p {
    font-size: 1.25rem;
    /* Smaller font size on mobile */
  }

  .home-pannel8-left-section h2 {
    font-size: 2rem;
    /* Smaller font size on mobile */
  }

  .home-pannel8-left-section li {
    font-size: 1.25rem;
    /* Smaller font size on mobile */
  }
}

@media (max-width: 480px) {
  .home-pannel8-header-content h1 {
    font-size: 2em;
    /* Further reduce font size on very small screens */
  }

  .home-pannel8-header-content p {
    font-size: 1rem;
    /* Further reduce font size on very small screens */
  }

  .home-pannel8-left-section h2 {
    font-size: 1.75rem;
    /* Further reduce font size on very small screens */
  }

  .home-pannel8-left-section li {
    font-size: 1rem;
    /* Further reduce font size on very small screens */
  }
}

/* ******************** pannel9 ************************** */

.home-pannel9-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.home-pannel9-text-content h1 {
  font-size: 2.5rem;
  /* Consider using vw units */
  margin: 0 0;
  text-transform: uppercase;
}

.home-pannel9-text-content p {
  font-size: 27px;
  /* Consider using responsive units */
  margin: 0 0 10px 0;
  text-transform: uppercase;
}

.home-pannel9-image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 15px;
}

.home-pannel9-image-box {
  overflow: hidden;
}

.home-pannel9-image-box img {
  width: 100%;
  /* Changed to 100% for better responsiveness */
  height: auto;
  display: block;
  transition: transform 0.3s ease;
}

.home-pannel9-image-box img:hover {
  transform: scale(1.05);
}

/* Media Queries for additional responsiveness */
@media (max-width: 1200px) {
  .home-pannel9-text-content h1 {
    font-size: 3.5em;
    /* Adjust based on screen size */
  }

  .home-pannel9-text-content p {
    font-size: 22px;
    /* Adjust based on screen size */
  }
}

@media (max-width: 768px) {
  .home-pannel9-text-content h1 {
    font-size: 3em;
    /* Adjust based on screen size */
  }

  .home-pannel9-text-content p {
    font-size: 18px;
    /* Adjust based on screen size */
  }
}

@media (max-width: 480px) {
  .home-pannel9-text-content h1 {
    font-size: 2.5em;
    /* Adjust based on screen size */
  }

  .home-pannel9-text-content p {
    font-size: 16px;
    /* Adjust based on screen size */
  }
}


/* ******************** pannel10 ************************** */

.home-pannel10-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  max-width: 900px;
  margin: auto;
}

.home-pannel10-container p {
  text-align: center;
  margin-bottom: 10px;
  font-size: 22px;
  margin-top: 10px;
  color: #666;
  text-transform: uppercase;
}

.home-pannel10-container h1 {
  text-align: center;
  font-size: 29px;
  margin: 0 0;
  text-transform: uppercase;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input[type='text'],
input[type='email'],
input[type='tel'],
textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

/* ******************** pannel11 ************************** */

.home-pannel11-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.home-pannel11-content {
  text-align: center;
}

.home-pannel11-heading {
  font-size: 30px;
  margin: 0px 0 0px;
  text-transform: uppercase;
}

.home-pannel11-paragraph {
  font-size: 27px;
  color: #666;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.home-pannel11-image-box {
  margin-bottom: 20px;
}

.home-pannel11-image-box img {
  max-width: 60%;
  height: auto;
}

.home-pannel11-footer {
  text-align: center;
}

.home-pannel11-footer a {
  display: block;
  margin-bottom: 10px;
  font-size: 1rem;
  color: blue;
  text-decoration: none;
}

.home-pannel11-footer .date {
  font-size: 0.875rem;
  color: gray;
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
  .home-pannel11-heading {
    font-size: 3.5em;
    /* Adjust heading size */
  }

  .home-pannel11-paragraph {
    font-size: 22px;
    /* Adjust paragraph size */
  }

  .home-pannel11-image-box img {
    max-width: 70%;
    /* Increase image size on medium screens */
  }
}

@media (max-width: 992px) {
  .home-pannel11-heading {
    font-size: 3em;
    /* Further adjust heading size */
  }

  .home-pannel11-paragraph {
    font-size: 20px;
    /* Further adjust paragraph size */
  }

  .home-pannel11-image-box img {
    max-width: 80%;
    /* Increase image size on smaller screens */
  }
}

@media (max-width: 768px) {
  .home-pannel11-heading {
    font-size: 2.5em;
    /* Smaller heading for mobile */
  }

  .home-pannel11-paragraph {
    font-size: 18px;
    /* Smaller paragraph for mobile */
  }

  .home-pannel11-footer a {
    font-size: 0.9rem;
    /* Slightly smaller links */
  }
}

@media (max-width: 576px) {
  .home-pannel11-heading {
    font-size: 2em;
    /* Heading for very small screens */
  }

  .home-pannel11-paragraph {
    font-size: 16px;
    /* Paragraph size for very small screens */
  }

  .home-pannel11-footer .date {
    font-size: 0.8rem;
    /* Smaller date text */
  }
}


/* ******************** pannel12 ************************** */

.home-pannel12-container {
  position: relative;
  padding: 0px;
}

.home-pannel12-view-all-button {
  display: block;
  margin: 0 auto 20px;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

.home-pannel12-content-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  /* Allow wrapping on smaller screens */
}

.home-pannel12-left-section,
.home-pannel12-right-section {
  width: 50%;
  color: rgb(61, 60, 60);
  padding-left: 150px;
}

.home-pannel12-left-section p {
  font-size: 17px;
}

.home-pannel12-left-section {
  text-align: left;
}

.home-pannel12-heading {
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.home-pannel12-heading h1 {
  margin-right: 10px;
  font-size: 28px;
}

.home-pannel12-right-section h2 {
  margin-bottom: 10px;
}

.home-pannel12-links-list {
  list-style: none;
  padding: 0;
  font-size: 30px;
}

.home-pannel12-links-list li {
  margin-bottom: 5px;
}

.home-pannel12-links-list a {
  text-decoration: none;
  font-size: 19px;
  color: #007bff;
}

.home-pannel12-links-list a:hover {
  text-decoration: underline;
}

/* Responsive Styles */
@media (max-width: 1200px) {

  .home-pannel12-left-section,
  .home-pannel12-right-section {
    width: 45%;
    /* Adjust width for medium screens */
    padding-left: 20px;
    /* Reduce padding */
  }

  .home-pannel12-heading h1 {
    font-size: 28px;
    /* Smaller heading on medium screens */
  }

  .home-pannel12-links-list {
    font-size: 24px;
    /* Adjust font size */
  }
}

@media (max-width: 768px) {

  .home-pannel12-left-section,
  .home-pannel12-right-section {
    width: 100%;
    /* Full width on small screens */
    padding-left: 0;
    /* Remove padding */
    margin-bottom: 20px;
    /* Add margin between sections */
  }

  .home-pannel12-view-all-button {
    font-size: 24px;
    /* Smaller button on small screens */
    padding: 8px 16px;
    /* Smaller padding */
  }

  .home-pannel12-heading h1 {
    font-size: 24px;
    /* Smaller heading on small screens */
  }

  .home-pannel12-links-list {
    font-size: 20px;
    /* Adjust font size */
  }
}


/* ******************** pannel13 ************************** */

.home-footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
  /* Use viewport height for better responsiveness */
  background-color: rgba(0, 102, 255, 0.93);
  padding: 1rem;
  /* Add some padding for better spacing */
}

.home-footer-container footer p {
  font-size: 25px;
  /* Use relative font size */
  color: white;
  text-align: center;
  /* Center text for better alignment */
  margin: 0;
  /* Remove default margin */
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .home-footer-container {
    height: 15vh;
    /* Adjust height on smaller screens */
  }

  .home-footer-container footer p {
    font-size: 1.5rem;
    /* Smaller font size for smaller screens */
  }
}

@media (max-width: 480px) {
  .home-footer-container {
    height: 70px;
    /* Increase height further on very small screens */
  }


  .home-pannel7-container img {
    height: auto;
  }

  .home-footer-container footer p {
    font-size: 1.2rem;
    /* Further decrease font size */
  }
}

/* ******************** About us -Section ************************** */

.About-section {
  display: flex;
  flex-wrap: wrap;
  /* Allow items to wrap */
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
  width: 90%;
  margin: 0 auto;
  /* Center the section */
}

.About-left-section {
  flex: 1 1 70%;
  /* Allow the section to grow and shrink */
  max-width: 70%;
  /* Limit max width */
}

.About-right-section {
  flex: 1 1 25%;
  /* Allow the section to grow and shrink */
  max-width: 25%;
  /* Limit max width */
}

.image-box img {
  width: 100%;
  height: auto;
}

.About-search-container {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
  /* Moved here for clarity */
}

.search-input {
  width: 100%;
  padding: 8px 32px 8px 8px;
  box-sizing: border-box;
}

.icon {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  pointer-events: none;
}

.About-links {
  margin-bottom: 20px;
}

.About-links a {
  display: block;
  margin-bottom: 5px;
  color: #007bff;
  line-height: 40px;
  font-size: 20px;
}

h1 {
  margin-top: 20px;
  font-size: 2.5rem;
  /* Use rem for better scalability */
}

h2 {
  margin-top: 20px;
  font-size: 2rem;
  /* Use rem */
}

h3 {
  margin-top: 20px;
}

p {
  font-size: 1.5rem;
  /* Use rem */
}

.About-content {
  margin-bottom: 20px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .About-section {
    flex-direction: column;
    /* Stack sections on smaller screens */
  }

  .About-left-section,
  .About-right-section {
    max-width: 100%;
    /* Allow sections to take full width */
    width: 100%;
  }

  .About-left-section {
    margin-bottom: 20px;
    /* Add space below the left section */
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 2rem;
    /* Adjust for small screens */
  }

  h2 {
    font-size: 1.5rem;
    /* Adjust for small screens */
  }

  h3 {
    font-size: 1.2rem;
    /* Adjust for small screens */
  }

  p {
    font-size: 1rem;
    /* Adjust for small screens */
  }

  .About-links a {
    font-size: 1rem;
    /* Adjust for small screens */
  }
}

/* ******************** Contact Us - Section ************************** */

.Contact-section {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
  width: 90%;
  padding-left: 150px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 20px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.form-group textarea {
  height: 100px;
  resize: vertical;
}

.form-group button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.form-group button:hover {
  background-color: #0056b3;
}

.Contact-left-section {
  width: 70%;
}

.Contact-right-section {
  width: 25%;
}

.Contact-search-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.Contact-search-wrapper {
  position: relative;
}

.Contact-search-input {
  width: 100%;
  padding: 8px 32px 8px 8px;
  box-sizing: border-box;
}

.Contact-icon {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  pointer-events: none;
}

.Contact-search-container {
  position: relative;
  margin-bottom: 20px;
}

.Contact-search-container input {
  padding-left: 30px;
}

.Contact-links {
  margin-bottom: 20px;
}

.Contact-links a {
  display: block;
  margin-bottom: 5px;
  color: #007bff;
  line-height: 40px;
  font-size: 20px;
}

h3 {
  margin-top: 20px;
}

h2 {
  margin-top: 20px;
  font-weight: 300;
  font-size: 18px;
}

p {
  font-size: 18px;
}

h1 {
  margin-top: 20px;
  font-size: 35px;
}

.About-content {
  margin-bottom: 20px;
}

/* Responsive styles */
@media (max-width: 1024px) {
  .Contact-section {
    flex-direction: column;
    padding-left: 20px;
  }

  .Contact-left-section,
  .Contact-right-section {
    width: 100%;
  }

  h2,
  h3,
  p,
  h1 {
    font-size: 20px;
    /* Adjust font sizes for smaller screens */
  }
}

@media (max-width: 768px) {
  .form-group label {
    font-size: 20px;
    /* Smaller font size for labels */
  }

  .form-group button {
    font-size: 14px;
    /* Smaller font size for buttons */
    padding: 8px 12px;
    /* Adjust button padding */
  }

  .Contact-links a {
    font-size: 18px;
    /* Adjust font size for links */
  }
}

@media (max-width: 480px) {
  .Contact-section {
    padding: 10px;
    /* Reduce padding on very small screens */
  }

  h2,
  h3,
  p,
  h1 {
    font-size: 18px;
    /* Further reduce font sizes */
  }

  .form-group {
    margin-bottom: 10px;
    /* Reduce margin for form groups */
  }

  .form-group input,
  .form-group textarea {
    padding: 6px;
    /* Adjust padding */
  }
}


@media screen and (max-width:600px) {
  .logo-nav {
    width: 120px;
  }

  .navbar {
    height: auto;
  }

  .hamburger {
    padding: 0px;
    padding-right: 5px;
  }



  .mnav_links {
    position: fixed;
    width: 40%;
    z-index: 100;
    background-color: white;
    top: 0px;
    margin-top: 0px;
    right: 0;
    height: 100vh;
  }

  .res-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    padding-top: 10px;
  }

  .f_bottom_text {
    font-size: 12px;
  }


  .main-div-12th {
    padding-right: 10px;
    padding-left: 10px;

    h1 {
      font-size: 25px;
    }
  }

  .online-test {
    padding: 10px 20px;

    h1 {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  .online-box {
    flex-direction: column;
  }

  .li-for-online-box {
    width: 100%;
  }

  .main-majortest {
    flex-direction: column;
  }

  .box-logo {
    margin-left: 20px !important;
  }

  .home-pannel12-left-section {
    padding-left: 15px;
    padding-right: 15px;
  }

  .home-pannel12-right-section {
    padding-left: 15px;
    padding-right: 15px;
  }

  .home-pannel12-view-all-button {
    font-size: 15px;
    padding: 5px 15px;
  }

  .Contact-left-section {
    h1 {
      font-size: 20px;
      font-weight: 550;
    }

    h2 {
      font-weight: 500;
    }

    h3 {
      font-weight: 400;
    }

    p {
      font-size: 16px;
      font-weight: 350;
    }
  }

  .form-group {
    label {
      font-size: 16px;
      font-weight: 400;
    }
  }

  .Contact-search-wrapper {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  #root>div>div.left-majortest>div:nth-child(1) {
    width: 341px;
  }

  #root>div>div.left-majortest>div:nth-child(1)>ul {
    width: 100% !important;
  }

  #root>div>div.left-majortest>div:nth-child(2)>div.text-base.rounded.font-semibold.text-primary.mb-4.md\:text-xl.md\:mb-6 {
    width: 300px;
  }

  #root>div>div.left-majortest>div:nth-child(2)>div.w-full>div>div.w-full.border-primary.border-b.border-dashed.pb-5.md\:pb-8.flex.gap-x-5.items-stretch {
    flex-direction: column;
  }

  #root>div>div.left-majortest>div:nth-child(2)>div.w-full>div>div.w-full.border-primary.border-b.border-dashed.pb-5.md\:pb-8.flex.gap-x-5.items-stretch>div.shrink-0.inline-flex.items-center {
    width: fit-content;
  }

  #root>div>div.left-majortest>div:nth-child(2)>div.w-full>div>div:nth-child(2) {
    flex-direction: column;
  }

  #root>div>div.left-majortest>div:nth-child(2)>div.w-full>div>div.w-full.border-primary.border-b.border-dashed.pb-5.md\:pb-8.flex.gap-x-5.items-stretch>div.flex-1 {
    width: 100% !important;
  }

  #root>div>div.left-majortest>div:nth-child(2)>div.w-full>div>div.w-full.border-primary.border-b.border-dashed.pb-5.md\:pb-8.flex.gap-x-5.items-stretch {
    width: fit-content !important;
  }

  #root>div>div.left-majortest>div:nth-child(2)>div.w-full>div>div:nth-child(3) {
    flex-direction: column;
  }

  #root>div>div.left-majortest>div:nth-child(2)>div.w-full>div>div.w-full.border-primary.pt-5.md\:pt-8.flex.gap-x-5.items-stretch {
    flex-direction: column;
  }

  #root>div>div.left-majortest>div:nth-child(2)>div.w-full>div>div:nth-child(2) {
    width: fit-content !important;
  }

  #root>div>div.left-majortest>div:nth-child(2)>div.w-full>div>div:nth-child(3)>div.flex-1 {
    width: fit-content !important;
  }

  #root>div>div.left-majortest>div:nth-child(2)>div.w-full>div>div:nth-child(3) {
    width: fit-content !important;
  }

  #root>div>div.left-majortest>div:nth-child(2)>div.w-full>div>div.w-full.border-primary.pt-5.md\:pt-8.flex.gap-x-5.items-stretch {
    width: fit-content !important;
  }

  #root>div>div.left-majortest>div:nth-child(2)>div.w-full>div {
    width: 100% !important;
  }

  #root>div>div.left-majortest>div:nth-child(2)>div.w-full {
    width: 100% !important;
  }

  #root>div>div.left-majortest {
    width: 100%;
  }

  #root>div>div.left-majortest>div:nth-child(2)>div.w-full>div>div.w-full.border-primary.border-b.border-dashed.pb-5.md\:pb-8.flex.gap-x-5.items-stretch>div.flex-1>div.w-full.mt-2\.5>ul {
    width: 70% !important;
  }

  #root>div>div.left-majortest>div:nth-child(2)>div.w-full>div>div.w-full.border-primary.border-b.border-dashed.pb-5.md\:pb-8.flex.gap-x-5.items-stretch {
    width: 100% !important;
  }

  #root>div>div.left-majortest>div:nth-child(2)>div.w-full>div>div.w-full.border-primary.border-b.border-dashed.pb-5.md\:pb-8.flex.gap-x-5.items-stretch>div.flex-1>div.w-full.mt-2\.5 {
    width: 100% !important;
  }

  #root>div>div.left-majortest>div:nth-child(2) {
    width: 100%;
  }

  #root>div>div.left-majortest>div:nth-child(2)>div.w-full>div>div.w-full.border-primary.border-b.border-dashed.pb-5.md\:pb-8.flex.gap-x-5.items-stretch>div.flex-1>div.w-full.flex {
    width: fit-content !important;
  }

  #root>div>div.left-majortest>div:nth-child(2)>div.w-full>div>div:nth-child(2)>div.shrink-0.inline-flex.items-center {
    width: fit-content !important;
  }

  #root>div>div.left-majortest>div:nth-child(2)>div.w-full>div>div:nth-child(2)>div.flex-1>div.w-full.mt-2\.5>ul {
    width: 100% !important;
  }

  #root>div>div.left-majortest>div:nth-child(2)>div.w-full>div>div:nth-child(2)>div.flex-1>div.w-full.flex {
    width: fit-content !important;
  }

  #root>div>div.left-majortest>div:nth-child(2)>div.w-full>div>div:nth-child(2)>div.flex-1>div.w-full.mt-2\.5 {
    width: 100% !important;
  }

  #root>div>div.left-majortest>div:nth-child(2)>div.w-full>div>div:nth-child(3)>div.shrink-0.inline-flex.items-center {
    width: fit-content !important;
  }

  #root>div>div.left-majortest>div:nth-child(2)>div.w-full>div>div:nth-child(3)>div.flex-1 {
    width: 100% !important;
  }

  #root>div>div.left-majortest>div:nth-child(2)>div.w-full>div>div:nth-child(3)>div.flex-1>div.w-full.flex {
    width: fit-content !important;
  }

  #root>div>div.left-majortest>div:nth-child(2)>div.w-full>div>div:nth-child(3)>div.flex-1>div.w-full.mt-2\.5>ul {
    width: 100% !important;
  }

  #root>div>div.left-majortest>div:nth-child(2)>div.w-full>div>div:nth-child(3)>div.flex-1>div.w-full.mt-2\.5 {
    width: 100% !important;
  }

  #root>div>div.left-majortest>div:nth-child(2)>div.w-full>div>div.w-full.border-primary.pt-5.md\:pt-8.flex.gap-x-5.items-stretch {
    width: 100% !important;
  }

  #root>div>div.left-majortest>div:nth-child(2)>div.w-full>div>div.w-full.border-primary.pt-5.md\:pt-8.flex.gap-x-5.items-stretch>div>div.w-full.mt-2\.5>ul {
    width: 100% !important;
  }

  #root>div>div.left-majortest>div:nth-child(2)>div.w-full>div>div.w-full.border-primary.pt-5.md\:pt-8.flex.gap-x-5.items-stretch>div>div.w-full.mt-2\.5 {
    width: 100% !important;
  }

  .download-syllabus {
    width: 100% !important;
  }

  #root>div>div.left-majortest>div:nth-child(2)>div.w-full>div>div.w-full.border-primary.pt-5.md\:pt-8.flex.gap-x-5.items-stretch>div>div.w-full.flex {
    width: fit-content !important;
  }

  .policy-btn {
    width: 100% !important;
    gap: 10px;
  }
}

.main-majortest {
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
  background-color: #f1f1f1;
}

.left-majortest {
  width: 60%;
  min-height: 100vh;
  border: 1px solid black;
}


.right-majortest {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
  position: sticky;
  top: 0;
}


.annul-details {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: start;
  background-color: #e5e5e5;
  padding: 20px 15px;
  border-radius: 15px;

}

.div1-anual-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  p {
    margin: 0px;
    font-size: 19px;
    font-weight: 700;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;

    .sub-price {
      font-size: 13px;
      text-decoration: line-through;
    }
  }
}


.language-select {
  width: 90%;
  margin-top: 20px;
  border-radius: 15px;
  border: 1px solid #ccc;


  .top {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0px 15px;


    h3 {
      width: 100%;
      display: flex;
      align-items: start;
      justify-content: start;
      font-size: 16px;
      margin: 0px;
      padding: 15px 10px;
      font-weight: 600;
    }


    div {
      padding: 18px 10px;
      width: 100%;
      gap: 5px;
      display: flex;
      align-items: center;
      justify-content: start;

      label {
        margin-bottom: 0px;
      }

      span {
        width: auto;
        height: auto;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #cfc5c5;
        padding: 3px 5px;
        border-radius: 5px;
      }
    }
  }


  .bottom {
    width: 100%;
    flex-direction: column;
    display: flex;
    align-items: start;
    justify-content: center;
    padding: 10px 20px;

    div {
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        margin: 0px;
        padding: 0px;
        font-size: 15px;
      }

    }

    p {
      font-size: 15px;
    }
  }
}


.buy-now {
  background-color: #b19d9d;
  width: 90%;
  margin: 20px 0px;
  font-size: 18px;
  border-radius: 20px;
  padding: 10px 20px;
}

.buy-now:hover {
  background-color: #b19d9d;

}


.left-majortest {
  border: none;

  padding: 20px 30px;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}



.download-syllabus {
  width: 30%;
  border-radius: 20px;
  font-size: 15px;
  display: flex;
  align-items: center;
  padding: 7px 15px;
  justify-content: space-between;
  background-color: #f1f1f1;
  border: 1px solid black;
  color: black;

  p {
    margin: 0px;
    padding: 0px;
  }

  &:hover {
    background-color: #f1f1f1;
  }
}


.policy-btn {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    background-color: #f1f1f1;
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid black;
    padding: 10px 10px;
    border-radius: 12px;

    p {
      margin: 0px;
      padding: 0px;
    }
  }
}